<template>
  <div class="d-flex flex-column align-items-center">
    
    <b-card style="border: none;" class="bg-transparent w-50">
      <b-alert :show="error === 'invalid_link'" variant="danger">{{$t('SHOP_ORDER.INVALID_LINK')}}</b-alert>
      <b-alert :show="error === 'get_orders'" variant="danger"
        >{{$t('SHOP_ORDER.ERROR_GET')}}</b-alert
      >

      <b-alert :show="error === 'invalid_stripe_setup'" variant="danger"
        >Stripe setup invalid</b-alert
      >

      <b-alert :show="error === 'no_orders'" variant="danger"
        >{{$t('SHOP_ORDER.NO_ORDERS')}}</b-alert
      >
      <b-alert :show="error === 'timeout'" variant="danger">
        {{$t('SHOP_ORDER.TIMEOUT')}}
      </b-alert>

    </b-card>

    <div v-if="cancel_in_seconds !== null && cancel_in_seconds > 0">
      <p>
        {{$t('SHOP_ORDER.CANCEL_IN_SECONDS', { seconds: cancel_in_seconds })}}
      </p>
    </div>


    <div v-if="!has_errors && order && !paymentSuccess && !timeout">

      <b-row align-h="center">
        <b-col xl="6" lg="8" md="12" sm="12" :style="min_width">
          <div class="mt-6 shadow-lg">
            <div
              class="d-flex flex-column align-items-center py-12 bg-linear-gradient-primary text-white"
            >
              <h3 class="text-center mt-0 mb-0">{{ }}</h3>
            </div>
            <b-card-body class="py-16">

              <PendingPaymentInfo 
                :member="{}" 
                :order="order" 
                :products="order ? order.shop_order_items : []" 
              />

              <!--<div class="d-flex justify-content-center mb-3">
                <memlist-spinner />
              </div>-->

              <form id="payment-form">
                <div id="payment-element">

                  <!--Stripe.js injects the Payment Element-->
                </div>
                
                <b-button v-if="stripe_done" class="mt-5" variant="success" @click.prevent="handleSubmit">{{$t('SHOP_ORDER.PAY_NOW')}}</b-button>

                <div ref="payment-message" id="payment-message">{{message}}</div>
              </form>

            </b-card-body>
          </div>
        </b-col>
      </b-row>

    </div>

    <div v-if="paymentSuccess">
      <PaymentComplete :member="{}" :order="order" :products="order ? order.shop_order_items : []" />
    </div>
    
    
  </div>
</template>

<script>
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PaymentOrderDetails from '@/view/components/PaymentOrderDetails.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';

import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {
  name: 'stripe-vue-wrapper',
  props: [],
  mixins: [ toasts ],
  components: {
    StripeElementCard,
    PaymentComplete,
    PaymentOrderDetails,
    PendingPaymentInfo
  },
  computed: {
    has_errors() {
      return this.error !== '';
    },
    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },
    
  },
  data() {
    return {
      has_errors: false,
      cancel_in_seconds: null,
      stripe_done: false,
      message: '',
      stripe: null,
      loader: null,
      url: '',
      elements: null,
      timeout: false,
      error: '',
      proceed: false,
      member_id: null,
      order: null,
      paymentSuccess: false,
      client_secret: null,
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },
      elementsOptions: {
        elementAppearence: { theme: "stripe" },
        /*elementClientSecret: async () => {
          return await retrieveClientSecret();
        },*/
        clientSecret: '',
        elementClientSecret: null
      }
    };
  },
  mounted() {
    let divScripts = document.getElementById('load-script');
    let newScript = document.createElement('script');
    newScript.src = 'https://js.stripe.com/v3/';
    divScripts.appendChild(newScript);

    this.member_id = this.$route.params.member_id;
    this.client_secret = this.$route.query.client_secret;
    //this.renew_id = this.$route.params.renew_id;

    if (!this.member_id || !this.client_secret) {
      this.error = 'invalid_link';
      this.has_errors = true;

      return;
    }

    const that = this;

    const interval_id = setInterval(function(){
      try {

        if (Stripe === undefined) {
          throw 'Stripe not defined';
        }

        that.getOrdersForMember();

        clearInterval(interval_id);
      }
      catch (err) {
        console.error('stripe not loaded, retry...', err);
      }
    }, 1000);
    
  },
  watch: {},
  methods: {
    check_cancellation() {

      if (this.cancel_in_seconds === null || this.cancel_in_seconds === undefined) {
        return;
      }

      const int_id = setInterval(()=>{
        
        this.cancel_in_seconds = this.cancel_in_seconds - 1;

        if (this.cancel_in_seconds <= 0) {
          this.timeout = true;
          this.error = 'timeout';
          this.has_errors = true;

          clearInterval(int_id);
        }
      }, 1000);
    },

    pay () {
      this.$refs.paymentRef.submit();
    },
    startStripe() {

      const that = this;

      setTimeout(function(){
        if (that.paymentSuccess) {
          return;
        }

        that.error = 'timeout';
        that.timeout = true;
        that.cancelOrder();
      }, 15 * 60 * 1000);

    },
    cancelOrder() {
      axios
        .put(`/shop_order/cancel/${this.order.shop_order_id}`, { token: this.order.token })
        .then(res => {
          
        })
        .catch(err => {
          console.error(err);
        });
    },
    getOrdersForMember() {
      axios
        .get(`/shop_order/stripe/${this.client_secret}`)
        .then(res => {
          if (res.status === 200) {
            if (res.data.order.is_cancelled) {
              this.error = 'timeout';
              this.timeout = true;
              this.has_errors = true;
              return;
            }

            if (res.data === null || res.data.length === 0) {
              this.error = 'no_orders';
              this.has_errors = true;
            } else {
              this.order = res.data.order;

              this.cancel_in_seconds = this.order.cancel_in_seconds;

              if (this.cancel_in_seconds !== null && this.cancel_in_seconds !== undefined) {
                this.check_cancellation();
              }


              const stripe_pk = res.data.stripe_pk;

              if (!stripe_pk) {
                this.error = 'invalid_stripe_setup';
                this.has_errors = true;
                return;
              }

              setKeyValue('stripe.pk', stripe_pk);

              this.stripe = Stripe(stripe_pk);

              const redirect_url = this.order.is_renew
                ? this.order.company.company_settings.post_renew_url
                : this.order.company.company_settings.post_register_url;

              if (this.order.type === 'INSURANCE') {
                this.url = `${window.location.origin}/profile`;
              }
              else {
                // add http:// if not present
                this.url = window.location.origin ? window.location.origin : '';
                  
                this.url = `${this.url}/payment-return/stripe`;
  
                if (this.url.indexOf('localhost') >= 0) {
                  this.url = 'http://localhost:8080/payment-return/stripe'
                }
  
                if (redirect_url && redirect_url > 4) {
                  if (!this.url.match(/^https?:\/\//)) {
                    this.url = 'http://' + this.url;
                  }
                }
              }
              

              setKeyValue('stripe.shop_order_id', this.order.shop_order_id);
              
              if (redirect_url && redirect_url !== '') {
                setKeyValue('stripe.redirect_url_after_return', redirect_url);
              }
              else {
                // no redirect set, so dont redirect after stripe
                setKeyValue('stripe.redirect_url_after_return', null);
              }
              
              this.$nextTick(()=>{
                this.create_stripe_frame();
              });
              
            }
          } else {
            this.error = 'get_orders';
            this.has_errors = true;
          }
        })
        .catch(err => {
          console.error(err);
          this.error = 'get_orders';
          this.has_errors = true;
        });
    },

    create_stripe_frame() {

      /// find #payment-element
      
      const html_element = document.getElementById('payment-element');

      if (!html_element) {
        const that = this;
        setTimeout(()=>{
          that.create_stripe_frame();
        }, 1000);

        console.log('stripe element not found, trying again...');

        return;
      }


      const appearance = {
        theme: 'stripe',
      };

      this.elements = this.stripe.elements({ appearance, clientSecret: this.client_secret });
      
      this.confirmParams.return_url = this.url;
      
      this.proceed = true;

      this.elementsOptions.clientSecret = this.client_secret;
      this.elementsOptions.elementClientSecret = this.client_secret;

      const paymentElementOptions = {
        layout: "tabs",
      };

      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
      
      this.startStripe();

      this.stripe_done = true;
    },

    setLoading(load) {
      if (load) {
        if (this.loader) {
          return;
        }

        this.loader = this.$loading.show();
        return;
      }

      this.loader & this.loader.hide();

      this.loader = null;
    },
    async handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      
      this.setLoading(true);

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: this.url//"http://localhost:4242/checkout.html",
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        this.showMessage(error.message);
      } else {
        this.showMessage("An unexpected error occurred.");
      }

      this.setLoading(false);
    },

    // Fetches the payment intent status after payment submission
    async checkStatus() {
      /*const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }*/

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(this.client_secret);

      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    },

    showMessage(message) {
      this.message = message;
    },

  }
};
</script>
